import React from "react"
import { Link } from "gatsby"

import Avatar from "@material-ui/core/Avatar"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    padding: "1rem",
    borderRadius: "1rem",
    border: "1px solid var(--color-teal)",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "var(--color-teal)",
  },
  message: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
}))

const EmailConfirmationSuccess = ({ showHeader = true }) => {
  const classes = useStyles()

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {showHeader && (
            <>
              <Avatar className={classes.avatar}>
                <MailOutlineIcon />
              </Avatar>
              <h3>Email Confirmed</h3>
            </>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.message}>
                <p className="large">
                  Thank you. Your email address has been confirmed.
                </p>
                <p className="large">
                  <Link to="/store/headset">Purchase a device</Link>
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  )
}

export default EmailConfirmationSuccess
