import React from "react"
import { PageProps } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import EmailConfirmationSuccess from "../components/auth/EmailConfirmationSuccess"

type DataProps = {}

const EmailConfirmationPage: React.FC<PageProps<DataProps>> = ({}) => {
  return (
    <Layout hasHero={false}>
      <SEO title="Confirm your Email" />
      <EmailConfirmationSuccess />
    </Layout>
  )
}

export default EmailConfirmationPage
